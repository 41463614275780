<template>
  <div id="storico-ordini" :style="{ height: `${height}px` }">
    <div :style="{ height: `${height}px` }">
      <div>
        <div>
          <div class="filtro">
            <el-input
              v-model="findModel"
              placeholder="MODELLO"
              suffix-icon="el-icon-search"
            ></el-input>
            <el-input
              v-model="findRef"
              placeholder="RIFERIMENTO"
              suffix-icon="el-icon-search"
            ></el-input>
            <el-input
              v-model="findFrontColor"
              placeholder="COLORE FRONTALE"
              suffix-icon="el-icon-search"
            ></el-input>
            <el-date-picker
              v-model="findDate"
              type="date"
              placeholder="DATA"
              format="yyyy/MM/dd"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <!-- <el-input
              v-model="findDate"
              placeholder="DATA"
              suffix-icon="el-icon-search"
            ></el-input>-->
          </div>
        </div>
        <div>
          <div v-if="historyLoading" class="progress">
            <el-progress
              type="line"
              :text-inside="true"
              :stroke-width="26"
              :percentage="percentage"
              color="@--color-primary"
            ></el-progress>
          </div>
          <el-table
            v-else
            class="history-table"
            :data="pagedHistoryRows"
            border
            stripe
            :height="heightTableBody"
            max-height="50vh"
            :row-style="{ height: '60px' }"
            empty-text="Nessun ordine"
            :header-cell-style="{ height: '71px' }"
          >
            <el-table-column label="DATA" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.date | asDate }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="ID"
              label="ORDINE N°"
              align="center"
              sortable
            ></el-table-column>
            <!-- <el-table-column label="CALIBRO-PONTE" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.calibro }}/{{ scope.row.naso }}</span>
              </template>
            </el-table-column>-->
            <el-table-column prop="tot" label="N° ARTICOLI" align="center"></el-table-column>
            <el-table-column
              prop="Notes"
              label="NOTE"
              align="center"
              min-width="200"
            ></el-table-column>
            <el-table-column label="STATO" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.row_state | traduciStato }}</span>
              </template>
            </el-table-column>

            <el-table-column label="VISUALIZZA ORDINE" align="center">
              <template slot-scope="scope">
                <el-button
                  @click="
                    visOrd = true;
                    details = scope.row.details;
                  "
                  type="text"
                  size="small"
                >VISUALIZZA</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="pagination">
          <el-pagination
            background
            @size-change="setSizePage"
            @current-change="setPage"
            :current-page.sync="page"
            :page-sizes="pageSizes"
            :page-size="this.pageSize"
            layout="sizes, jumper, total, prev, next"
            :total="this.orderHistory.length"
          ></el-pagination>
        </div>

        <el-dialog
          class="details-dialog"
          width="75%"
          :title="'DETTAGLIO ORDINE N° ' + this.idDetails"
          :visible.sync="visOrd"
          :modal="false"
          :show-close="false"
        >
          <el-table
            class="history-table"
            :data="details"
            border
            stripe
            empty-text="Nessun Dettaglio"
            max-height="500"
          >
            <el-table-column label="STATO" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.row_state | traduciStato }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ID" label="ID" align="center"></el-table-column>
            <el-table-column prop="COD_PROD" label="MODELLO" align="center"></el-table-column>
            <el-table-column label="CALIBRO/PONTE" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.size_eye }}/{{ scope.row.size_bridge }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="COLORE"
              label="COLORE FRONTALE"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="Finishing"
              label="FINITURA FRONTALE"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.Finishing == 'S'">LUCIDO</span>
                <span v-else-if="scope.row.Finishing == 'M'">OPACO</span>
                <span v-else>NATURALE</span>
              </template>
            </el-table-column>
            <el-table-column prop="COD_ASTE" label="ASTE" align="center"></el-table-column>
            <el-table-column
              prop="FinishingTemple"
              label="FINITURA ASTE"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.FinishingTemple == 'M'">OPACO</span>
                <span v-else-if="scope.row.FinishingTemple == 'S'">LUCIDO</span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="nome"
              label="PERSONALIZZAZIONE"
              align="center"
            ></el-table-column>
            <el-table-column label="COLORE PERSONALIZZAZIONE" align="center">
              <template slot-scope="scope">
                {{
                scope.row.colore_nome.toUpperCase()
                }}
              </template>
            </el-table-column>-->
            <el-table-column label="PERSONALIZZAZIONE" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.nome !== ''">{{ scope.row.nome }}</span>
                <span
                  v-if="scope.row.EST_ASTA_DX_NOME !== ''"
                >{{ scope.row.EST_ASTA_DX_NOME }}</span>
                <span
                  v-if="scope.row.INT_ASTA_DX_NOME !== ''"
                >{{ scope.row.INT_ASTA_DX_NOME }}</span>
              </template>
            </el-table-column>
            <el-table-column label="COLORE PERSONALIZZAZIONE" align="center">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.colore_nome !== ''"
                >{{ scope.row.colore_nome.toUpperCase() }}</span>
                <span
                  v-if="scope.row.EST_ASTA_DX_COLORE_NOME !== ''"
                >{{ scope.row.EST_ASTA_DX_COLORE_NOME.toUpperCase() }}</span>
                <span
                  v-if="scope.row.INT_ASTA_DX_COLORE_NOME !== ''"
                >{{ scope.row.INT_ASTA_DX_COLORE_NOME.toUpperCase() }}</span>
              </template>
            </el-table-column>
            <el-table-column label="POSIZIONE PERSONALIZZAZIONE" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.nome !== ''">FRONTALE</span>
                <span
                  v-if="scope.row.EST_ASTA_DX_NOME !== ''"
                >ESTERNO ASTA DESTRA</span>
                <span v-if="scope.row.INT_ASTA_DX_NOME !== ''">TOP ASTA DESTRA</span>
              </template>
            </el-table-column>
            <el-table-column prop="Note" label="RIFERIMENTO" align="center"></el-table-column>
            <el-table-column
              prop="ID_RIGA"
              label="CODICE ASSISTENZA"
              align="center"
            ></el-table-column>
          </el-table>
        </el-dialog>
      </div>
      <!-- <div class="note-and-buttons">
        <el-input
          type="textarea"
          :rows="6"
          placeholder="Note"
          v-model="noteCarrello"
        ></el-input>
        <el-button-group class="action-group-button">
          <el-button type="default" @click="svuotaCarrello">SVUOTA CARRELLO</el-button>
          <el-button type="default" @click="confermaOrdine">CONFERMA ORDINE</el-button>
        </el-button-group>
      </div>-->
    </div>
  </div>
  <!-- <div id="storico-ordini">
    <h1>Storico Ordini</h1>
  </div>-->
</template>

<script>
  import jslinq from "jslinq";
  import { mapActions, mapState } from "vuex";
  export default {
    data() {
      return {
        findModel: "",
        findRef: "",
        findFrontColor: "",
        findDate: "",
        page: 1,
        pageSize: 5,
        pageSizes: [5, 10, 20, 50, 100],
        height: 0,
        // storicoOrdini: [],
        // storicoDettagli: [],
        visOrd: false,
        details: [],
        data: "",
        idDetails: 0,
      };
    },

    mounted: async function () {
      await this.getUserHistory();
      this.height = window.innerHeight - 80;
    },
    filters: {
      asDate(d) {
        let date = d.split(" ")[0];
        return date.split("-").reverse().join("/");
      },
    },
    methods: {
      ...mapActions("user", ["getUserHistory"]),
      setSizePage(val) {
        this.pageSize = val;
      },

      setPage(val) {
        this.page = val;
      },
    },
    computed: {
      ...mapState("user", {
        orderHistory: "orderHistory",
        historyLoading: "historyLoading",
        percentage: "percentage",
      }),
      pagedHistoryRows() {
        return this.filteredByFilter.slice(
          this.pageSize * this.page - this.pageSize,
          this.pageSize * this.page
        );
      },
      heightTableBody() {
        return this.pageSize * 60 + 71 + 1; // 60 è l'altezza delle righe della tabella impostata nel row-style, 71 è l'altezza dell'header
      },
      queryFilter() {
        return jslinq(this.orderHistory);
      },
      filteredByFilter() {
        let filtered = this.queryFilter.where((det) => {
          let hasModel = jslinq(det.details).any((c) => {
            return (
              c.COD_PROD.indexOf(this.findModel) >= 0 &&
              c.Note.indexOf(this.findRef) >= 0 &&
              c.COLORE.indexOf(this.findFrontColor) >= 0
            );
          });
          // return hasModel && det.date.indexOf(this.findDate) >= 0;
          return hasModel;
        });
        // .where((c) => {
        //   return c.date.indexOf(this.findDate) >= 0;
        // })
        // .toList();
        if (this.findDate != null) {
          filtered = filtered
            .where((c) => {
              return c.date.indexOf(this.findDate) >= 0;
            })
            .toList();
        } else {
          filtered = filtered.toList();
        }

        return filtered;
      },
    },
    watch: {
      details(newVal) {
        if (newVal) {
          this.idDetails = this.details[0].ID;
        }
      },
      // pagedHistoryRows(newVal) {
      //   if (newVal) {
      //     let date = jslinq(this.pagedHistoryRows).where((c) => {
      //       return c.ID == this.details[0].ID;
      //     });
      //     if (date > 0) {
      //       this.data = date.date;
      //     }
      //   }
      // },
    },
  };
</script>

<style lang="less">
  #storico-ordini {
    width: 100%;

    .filtro {
      display: flex;
      flex-direction: row;
      .el-input {
        margin-right: 20px;
      }
      .el-date-editor {
        margin-right: 20px;
        width: 80%;
      }
    }

    @media (max-width: 992px) {
      .filtro {
        width: 100%;
        padding: 25px 10px;
      }
    }

    @media (min-width: 992px) {
      .filtro {
        width: 80%;
        padding: 40px 15px 40px 15px;
      }
    }

    .details-dialog {
      background-color: rgba(0, 0, 0, 0.59);
      padding-top: 5%;
    }

    .history-table {
      background-color: @--table-header-background-color;
      .el-table__header {
        height: 71px;
      }

      .el-table__header,
      .el-table__body {
        div {
          word-break: normal !important;
        }
      }

      .el-table__empty-text {
        color: @--color-primary;
        font-weight: bold;
        font-size: 16px;
      }
    }

    .progress {
      padding: 20px;

      .el-progress-bar {
        width: 50%;
      }
      .el-progress__text {
        padding: 5px;
      }
    }

    .pagination {
      width: 100%;
      height: auto;
      min-height: 50px;
      .el-pagination {
        margin: 20px 0px;
        float: right;
        color: @--color-primary;
      }
    }
  }
</style>